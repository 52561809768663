<template>
  <div class="p-0 bg-white" >
    <div class="bg-white p-2">
      <div class="pt-2 px-1 grid grid-cols-12 gap-x-2">
        <div class="box col-span-7 bg-white h-full cust_card_comp">
          <div>
            <div class="p-2 grid grid-cols-12">
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">Lead Name</p>
                <p>{{pageObj.leadName !== '' ? pageObj.leadName : '-'}}</p>
              </div>
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">Email Address</p>
                <p>{{pageObj.emailAddress !== '' ? pageObj.emailAddress : '-'}}</p>
              </div>
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">Phone Number</p>
                <p v-if="pageObj.phoneNumber !== ''">{{pageObj.phoneNumber | phone}}</p>
                <p v-else>-</p>
              </div>
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">Company Name</p>
                <p>{{pageObj.companyName !== '' ? pageObj.companyName : '-'}}</p>
              </div>
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">Notes</p>
                <p>{{pageObj.notes !== '' ? pageObj.notes : '-'}}</p>
              </div>
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">Requsted IP</p>
                <p>{{pageObj.requestedIp !== '' ? pageObj.requestedIp : '-'}}</p>
              </div>
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">Requst URL</p>
                <p>{{pageObj.requestUrl !== '' ? pageObj.requestUrl : '-'}}</p>
              </div>
              <div class="box col-span-12 p-2 pb-0">
                <p class="label_css">Added Date</p>
                <p v-if="pageObj.addedDate !== ''">{{pageObj.addedDate | dateTimeFilter}}</p>
                <p v-else>-</p>
              </div>
            </div>
          </div>
          <!-- <div class="p-2 pb-5 text-right">
            <div>
              <button class="list_top_card_btn bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl" @click="$router.go(-1)">Discard</button>
              <button class="list_top_card_btn bg-primary cta-text text-white py-2 px-4 mr-2 rounded-3xl" @click="saveNewPage()">Save</button>
            </div>  
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HRM from '@/View/CMS/api/HRM.js'
export default {
  components: {
  },
  data () {
    return {
      pageDetailObj: [],
      pageObj: {
      }
    }
  },
  created() {
  },
  mounted () {
    document.title = this.$route.params.pageMasterId > 0 ? 'Lead Detail' : ''
    console.log('this.$123 route.params', this.$route.params)
    console.log('this.$123 route.name', this.$route.name)
    console.log('this.$123 router', this.$router)
    if (JSON.parse(this.$route.params.pageMasterId) > 0) {
      this.getPageStructDetail()
    }
  },
  watch: {
  },
  methods: {
    getPageStructDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      HRM.LeadSpecificDetail(
        JSON.parse(this.$route.params.pageMasterId),
        response => {
          this.pageDetailObj = response.Data === null ? [] : response.Data
          console.log("response pageDetailObj", this.pageDetailObj)
          console.log("response pageObj", this.pageObj)
          this.pageObj = {
            leadName: this.pageDetailObj.leadName,
            emailAddress: this.pageDetailObj.emailAddress,
            phoneNumber: this.pageDetailObj.phoneNumber,
            companyName: this.pageDetailObj.companyName,
            notes: this.pageDetailObj.notes,
            projectDetailId: this.pageDetailObj.projectDetailId,
            requestUrl: this.pageDetailObj.requestUrl,
            addedDate: this.pageDetailObj.addedDate,
            leadId: this.pageDetailObj.leadId,
            requestedIp: this.pageDetailObj.requestedIp
          }
          // this.pageObj.pageSections = this.pageDetailObj.pageSections === null ? [] : this.pageDetailObj.pageSections

          // for (let index = 0; index < this.pageObj.pageSections.length; index++) {
          //   this.pageObj.pageSections[index].isShow = true
          //   if (this.pageObj.pageSections[index].pageSectionFields !== null) {
          //     for (let k = 0; k < this.pageObj.pageSections[index].pageSectionFields.length; k++) {
          //       this.pageObj.pageSections[index].pageSectionFields[k].selComponentName = this.pageObj.pageSections[index].pageSectionFields[k].componentName
          //       if (this.pageObj.pageSections[index].pageSectionFields[k].componentMasterId > 0) {
          //         this.pageObj.pageSections[index].pageSectionFields[k].isExist = true
          //       } else {
          //         this.pageObj.pageSections[index].pageSectionFields[k].isExist = false
          //       }
          //     }
          //   }
          // }
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
  },
  beforeDestroy () {
    this.$root.$off('closeComponentDialog')
  }
}
</script>
<style scoped>
.list_top_card_btn {
  width: 163px !important;
  margin-right: 15px !important;
}

::placeholder {
  font-size: 16px;
}
</style>